import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import { Space } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"
import { Title } from "../../ui/atoms/Typography"
import { LinkButton } from "../../ui/atoms/CTA"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Layout } from "../Layout"

import Logo from "../../../images/logo-typestack.svg"
import { calculateRem } from "../../../utils/javascript-styles"

const StyledNav = styled.nav`
  padding: ${calculateRem(22)};
  background-color: white;
  position: fixed;
  z-index: 100;
  width: 100%;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.1);
`

const MenuItemLogo = styled.img`
  margin-bottom: 0;
  height: ${calculateRem(45)};
`

const Post = ({ data }) => {
  console.log("data", data)
  const post = data.wordpressPost

  return (
    <Layout>
      <StyledNav>
        <Grid>
          <Row>
            <Col xs={12}>
              <MenuItemLogo src={Logo} alt="logo" />
            </Col>
          </Row>
        </Grid>
      </StyledNav>
      <Section>
        <Grid>
          <Row center="md">
            <Col xs={12}>
              <Title secondary>{post.title}</Title>
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
              <Space mb={64} />
              <LinkButton to="/" secondary>
                Back
              </LinkButton>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
